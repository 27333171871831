import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '../common/models/loading.model';
import RVSWeightSettingsModel from './models/rvs-weights-settings.model';

@Module
export default class SettingsStore extends VuexModule {
    rvsWeight = {
        data: null as RVSWeightSettingsModel | null,
        loading: new LoadingModel(),
    };
}
