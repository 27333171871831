import { injectable } from '@/inversify.injectable';
import { inject } from '@/inversify.inject';
import { KEY } from '@/inversify.keys';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';

import SettingsApiService from '../settings-api.service';
import SettingsStore from '../settings.store';

@injectable()
export default class RVSWeightSettingsService {
    @inject(KEY.SettingsApiService)
    private settingsApiService!: SettingsApiService;

    @inject(UserServiceS)
    private userService!: UserService;

    @inject(HelperServiceS)
    private helperService!: HelperService;

    @inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    private readonly settingsStore: SettingsStore = this.storeFacade.getState('SettingsStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.userService.currentHotelId,
            this.userService.chainId,
        ], () => {
            this.storeState.loading.reset();
        });
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get storeState() {
        return this.settingsStore.rvsWeight;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.data;
    }

    async updateWeights(hotelId: number | number[], newWeights: Record<string, number>) {
        const { chainId } = this.userService;
        if (!chainId || !this.data) return;

        const convertedWeights: Record<string, number> = {};
        Object.keys(newWeights).forEach(key => {
            convertedWeights[key] = +(newWeights[key] / 100).toFixed(4);
        });

        await this.settingsApiService.updateWeightsSettings(hotelId, chainId, convertedWeights);
        this.data.weights = newWeights;
    }

    private async loadData() {
        const { currentHotelId, chainId } = this.userService;
        if (!currentHotelId || !chainId) return false;

        this.storeState.data = await this.settingsApiService.getWeightsSettings(currentHotelId, chainId);

        return true;
    }
}
