import { Expose, plainToClass, Transform } from 'class-transformer';
import _ from 'lodash';

import RatesDocumentItemModel from './rates-document-item.model';

type RoomTypeId = string;
type RateStatistic = 'lowest';
type Provider = string;

export default class RatesProviderDataModel {
    @Expose()
    @Transform((rooms: Record<RoomTypeId, any[]>, plain) => {
        const record = {} as Record<RoomTypeId, RatesDocumentItemModel[]>;

        Object.entries(rooms || {}).forEach(([roomTypeId, rooms]) => {
            record[roomTypeId] = plainToClass(RatesDocumentItemModel, rooms, { excludeExtraneousValues: true });
        });

        if (_.isEmpty(record) && plain.statistics) {
            const statisticRoom = Object.assign(new RatesDocumentItemModel(), {
                price: {
                    totalPrice: plain.statistics.lowest,
                    netPrice: plain.statistics.lowest,
                    shownPrice: plain.statistics.lowest,
                    lowestPrice: plain.statistics.lowest,
                },
            });

            record[0] = [statisticRoom];
        }

        return record;
    })
    rooms!: Record<RoomTypeId, RatesDocumentItemModel[]>;

    @Expose({ name: 'los_restricted' })
    losRestriction!: number | false;

    @Expose({ name: 'statistics' })
    statistics!: Record<RateStatistic, number>;

    @Expose({ name: 'update_date' })
    updateDate?: string | null;

    @Expose()
    link!: string | null;

    @Expose()
    screenshot!: string | null;

    @Expose()
    @Transform(pax => (pax && pax.length ? pax : null))
    pax?: number[] | null;

    @Expose()
    @Transform((_, plain) => {
        const rooms = Object.values(plain.rooms || {}) as RatesDocumentItemModel[][];
        return plain.rooms
            ? plainToClass(RatesDocumentItemModel, rooms[0]?.[0])
            : null;
    })
    room!: RatesDocumentItemModel | null;
}

export interface RatesCheckinDayAll extends Record<Provider, RatesProviderDataModel> {}
