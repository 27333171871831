import { inject, injectable } from '@/inversify';
import moment from 'moment';

import { toTrendKey } from '@/modules/common/filters/to-trend-key.filter';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import type Day from '@/modules/common/types/day.type';
import Price from '@/modules/common/types/price.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';

import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import CompsetModel from '@/modules/compsets/models/compset.model';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesStore from '@/modules/rates/store/rates.store';
import ClusterStore from '@/modules/cluster/store/cluster.store';

import RatesPriceHistoryApiService, { RatesPriceHistoryApiServiceS } from './rates-price-history-api.service';
import RatesPriceHistoryModel from './models/rates-price-history.model';
import RatesPriceHistoryFilterAllModel from './models/rates-price-history-filter-all.model';
import RatesPriceHistoryStore from './store/rates-price-history.store';
import RatesDocumentItemModel from '../rates/models/rates-document-item.model';
import RatesCheckinDayModel from '../rates/models/rates-checkin-day.model';
import RatesProviderDataModel, { RatesCheckinDayAll } from '../rates/models/rates-provider-data.model';

// [TODO] Common service must be pure.
// Remove all rates/cluster/analysis/... injections.
// Create small price history services for each price history popup type (rates hotel/cluster/all/cheapest/...)
// No need to store documents and filters in price history store they can be directly accesed from correspondent service
// Don't get data via components lifecircle, use watchers in small services.

export const RatesPriceHistoryCommonServiceS = Symbol.for('RatesPriceHistoryCommonServiceS');
@injectable()
export default class RatesPriceHistoryCommonService implements Stateable {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesPriceHistoryApiServiceS) private ratesPriceHistoryApiService!: RatesPriceHistoryApiService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    readonly ratesStoreState: RatesStore = this.storeFacade.getState('RatesStore');
    readonly clusterStoreState: ClusterStore = this.storeFacade.getState('ClusterStore');
    readonly storeState: RatesPriceHistoryStore = this.storeFacade.getState('RatesPriceHistoryStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.docDay,
                this.rateDocuments,
            ],
            () => {
                this.setTableDay(0);
                this.storeState.loading.reset();
            },
        );
    }

    get rateDocuments() {
        return this.storeState.rateDocuments;
    }

    set rateDocuments(value) {
        this.storeState.rateDocuments = value;
    }

    get isAllChannels() {
        return this.rateDocuments?.main?.providerName === 'all';
    }

    get comparisonValues() {
        return this.ratesAnalysisFiltersService.comparisonValues;
    }

    get sortedDaysList() {
        if (!this.lastScanDate || !this.documentSettings) {
            return [];
        }

        const scanDay = (new Date(this.lastScanDate.getTime() + this.lastScanDate.getTimezoneOffset() * 60 * 1000)).getDate();
        const {
            month,
            year,
        } = this.documentSettings;

        const days = this.documentFiltersService.getDays(year, month);

        const maxDaysPrevMonth = new Date(year, month + 1, 0).getDate();
        const currentMonthSegment = Array(scanDay)
            .fill(null)
            .map((_, i) => i + 1);

        const prevMonthSegment = days.length === scanDay ? [] : Array(Math.abs(maxDaysPrevMonth - scanDay))
            .fill(null)
            .map((_, i) => i + scanDay + 1);

        return [...prevMonthSegment, ...currentMonthSegment].reverse();
    }

    get ratesSettings() {
        return this.storeState.ratesSettings;
    }

    set ratesSettings(value: Record<string, RatesSettingsModel | null> | null) {
        this.storeState.ratesSettings = value;
    }

    get documentSettings() {
        return this.storeState.documentSettings;
    }

    set documentSettings(s: DocumentFiltersModel | null) {
        this.storeState.documentSettings = s;
    }

    get compset() {
        return this.storeState.compset;
    }

    set compset(c: CompsetModel | null) {
        this.storeState.compset = c;
    }

    get lastScanDate() {
        if (!this.rateDocuments?.main) {
            return new Date(Date.UTC(0, 0, 0));
        }

        if (this.docDay === null) {
            return this.rateDocuments.main.updateDate;
        }

        return this.ratesCommonService.getUpdateDate(this.docDay, this.rateDocuments.main);
    }

    get loading() {
        return this.storeState.loading;
    }

    set documents(value) {
        this.storeState.trendDocuments = value;
    }

    get documents() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.loadData.bind(this),
        );

        return this.storeState.trendDocuments;
    }

    get currency() {
        return this.storeState.currency;
    }

    set currency(value: string | null) {
        this.storeState.currency = value;
    }

    get docDay() {
        return this.storeState.documentDay;
    }

    set docDay(value: Day | null) {
        this.storeState.documentDay = value;
    }

    get dayIndex() {
        return this.storeState.dayIndex;
    }

    get localPriceShown() {
        return this.storeState.priceShown;
    }

    set localPriceShown(priceShown: PRICE_SHOWN | null) {
        this.storeState.priceShown = priceShown;
    }

    get selectedTrendDate() {
        const { dayIndex } = this.storeState;

        if (!this.lastScanDate) {
            return null;
        }

        const d = new Date(this.lastScanDate);
        d.setDate(d.getDate() - dayIndex);

        return d;
    }

    // Keys for all channels
    get providers() {
        const doc = this.documents.main as RatesPriceHistoryFilterAllModel;

        if (!doc || !doc.trendData) {
            return [];
        }

        const providers = Object
            .entries(doc.trendData!)
            .map(([, el]) => (el ? Object.keys(el) : []))
            .flat();

        if (!providers.includes('average')) {
            providers.push('average');
        }

        const filteredProviders = [...new Set(providers)]
            .filter(item => item !== 'day_statistics');

        return filteredProviders.length
            ? filteredProviders
            : null;
    }

    // Keys for regular price history
    get hotels() {
        if (!this.compset || !this.documentSettings) {
            return null;
        }

        return [this.compset.ownerHotelId].concat(this.documentSettings.competitors);
    }

    get isTrendsLoading() {
        return this.storeState.loading.isLoading();
    }

    get isNoData() {
        const { trendData } = this.documents.main || {};

        return trendData
            ? Object.keys(trendData).length === 0
            : true;
    }

    async loadData(): Promise<boolean> {
        if (!this.rateDocuments?.main || !this.ratesSettings?.main) {
            return this.storeState.isRatesDocumentLoading;
        }

        // Make promises for each rates document to request trends.
        const promises = Object.keys(this.rateDocuments).map(documentKey => {
            const settings = this.ratesSettings![documentKey];
            const rateDocument = this.rateDocuments![documentKey];

            if (!rateDocument || !settings) {
                return Promise.resolve(null);
            }

            this.documents[documentKey] = this.getInitialDocument(rateDocument, this.docDay as Day, this.lastScanDate);

            // [TODO] remove, when services will be splitted
            if (this.rateDocuments?.main?.providerName === 'cheapest') {
                return Promise.resolve(null);
            }

            return this.ratesPriceHistoryApiService
                .getRatesPriceHistoryByDay(this.docDay as Day, Number(rateDocument.id), settings, this.currency);
        });

        const data = await Promise.all(promises);

        // Loop through each rates document and put trends data to store.
        Object.keys(this.rateDocuments).forEach((key, index) => {
            if (this.rateDocuments && this.rateDocuments[key] && data[index] && this.lastScanDate) {
                const checkinDate = this.getCheckinDataFromDocument(this.docDay as Day, this.rateDocuments[key]!);
                const lastScanKey = toTrendKey(this.lastScanDate);
                data[index]!.trendData[lastScanKey] = checkinDate;
                this.documents[key] = data[index];
            }
        });

        return true;
    }

    setData(actualDay: Day | null) {
        if (!this.compset) {
            return;
        }

        if (this.docDay === actualDay) {
            return;
        }

        this.checkIsDocumentUpToDate(actualDay);
    }

    initRatesData(
        documents: Record<string, RatesDocumentModel | RatesDocumentAllModel | null> | null,
        ratesSettings: Record<string, RatesSettingsModel | null> | null,
        documentSettings: DocumentFiltersModel | null,
        compset: CompsetModel,
        day: Day,
        isDocumentLoading: boolean,
    ) {
        this.ratesSettings = ratesSettings;
        this.documentSettings = documentSettings;
        this.compset = compset;
        this.storeState.isRatesDocumentLoading = !isDocumentLoading;

        if (!documents) {
            return;
        }

        this.currency = (documents.main && documents.main.currency) ? documents.main.currency : null;
        this.rateDocuments = documents;

        this.documents = { main: null };

        // Loading resets when docDay changes. It is tracked in the service's watcher
        this.docDay = day;
    }

    checkIsDocumentUpToDate(actualDay: Day | null) {
        const { finishDate, startDate } = this.storeState.loading;

        if (this.docDay as Day !== actualDay
            && ((finishDate === null && startDate === null) || (finishDate !== null && startDate !== null))) {
            this.docDay = actualDay;
            this.storeState.loading.reset();
        }
    }

    setTableDay(index: number) {
        this.storeState.dayIndex = index;
    }

    getCompsetPriceHistory(
        day: Day,
        doc: RatesDocumentModel,
    ): Price | null {
        if (!this.compset) {
            return null;
        }

        return this.getPriceHistoryByCompsetType(day, doc, this.compset.type);
    }

    getPriceHistoryByCompsetType(
        day: Day,
        doc: RatesDocumentModel,
        compsetType: COMPSET_TYPE,
    ): Price | null {
        if (!this.compset || !this.documentSettings) {
            return null;
        }

        const rooms = this.ratesCommonService
            .getCompetitorsRooms(day, doc);

        delete rooms[this.compset.ownerHotelId];

        const { priceShown } = this.documentSettings;

        return this.ratesCommonService
            .getCompsetPrice(rooms, compsetType, priceShown);
    }

    getDemand() {
        if (!this.selectedTrendDate || !this.documents.main) {
            return null;
        }

        const iso = moment(this.selectedTrendDate).format('DD-MM-YYYY');
        const data = this.documents.main.trendData[iso];

        if (!data || data.demand === null) {
            return null;
        }

        return data.demand as number;
    }

    getOccupancy() {
        if (!this.selectedTrendDate || !this.documents.main) {
            return null;
        }

        const iso = moment(this.selectedTrendDate).format('DD-MM-YYYY');
        const data = this.documents.main.trendData[iso];

        if (!data || data.occupancy === null) {
            return null;
        }

        return data.occupancy as number;
    }

    // Is not used for all channels
    getPricesTrend(hotelId: number, priceShown: PRICE_SHOWN, documentKey: string) {
        if (!this.lastScanDate) {
            return [];
        }

        const document = this.documents[documentKey];
        const rateDocument = this.rateDocuments && this.rateDocuments[documentKey];

        if (!document || !rateDocument || rateDocument.providerName === 'all') {
            return [];
        }

        const trend = [] as (number | null)[];

        for (let i = 0; i < this.sortedDaysList.length; i += 1) {
            // Start from last updated date and - 24 hours in miliseconds each iteration
            const dateKey = moment(new Date(
                this.lastScanDate.getTime() + this.lastScanDate.getTimezoneOffset() * 60 * 1000 - i * 24 * 60 * 60 * 1000,
            )).format('DD-MM-YYYY');

            const documentDayData = (document as RatesPriceHistoryModel).trendData[dateKey];

            if (!documentDayData
                || !documentDayData.hotels[hotelId as number]?.rooms
                || !Object.keys(documentDayData.hotels[hotelId as number].rooms).length
            ) {
                trend.push(null);
            } else {
                const { rooms } = (document as RatesPriceHistoryModel).trendData[dateKey]!.hotels[hotelId as number];
                const roomTypeId = Number(Object.keys(rooms!)[0]);
                const lastScanPrice = this.ratesCommonService.switchPrice(rooms![roomTypeId]![0]!, priceShown);
                trend.push(lastScanPrice);
            }
        }

        return trend.reverse();
    }

    private getCheckinDataFromDocument(day: Day, doc: RatesDocumentModel | RatesDocumentAllModel) {
        const cd = doc.checkinDates ? doc.checkinDates[day] : null;

        if (!cd) {
            return null;
        }

        if (doc instanceof RatesDocumentModel) {
            return cd as RatesCheckinDayModel;
        }

        return cd as RatesCheckinDayAll;
    }

    private getInitialDocument(
        data: RatesDocumentModel | RatesDocumentAllModel | null,
        day: Day,
        scanDate: Date | null,
    ) {
        // [TODO] split for each mode
        if (data && !scanDate && this.rateDocuments?.main?.providerName === 'cheapest') {
            return {
                requestDay: +day,
                trendData: { 0: data.checkinDates ? data.checkinDates[day] : null },
            } as RatesPriceHistoryModel;
        }

        if (!data || !scanDate) {
            return null;
        }

        const date = toTrendKey(scanDate);
        const checkinDates = this.getCheckinDataFromDocument(this.docDay as Day, data);

        const doc = {
            requestDay: day,
            trendData: { [date]: checkinDates },
        };

        return doc as RatesPriceHistoryModel | RatesPriceHistoryFilterAllModel;
    }
}
