import { inject, injectable } from '@/inversify';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import UserSettings from '@/modules/user/store/user-settings.store';
import { $enum } from 'ts-enum-util';
import CurrencyListModel from './models/currency-list.model';
import UserApiService, { UserApiServiceS } from './user-api.service';
import UserStore from './store/user.store';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import HotelsService, { HotelsServiceS } from '../hotels/hotels.service';
import HotelNotifications from './constants/hotel-level-notifications';
import { DefaultFilters } from './types';
import DefaultSettingsModel from './models/default-settings.model';
import SpecialDateModel from './models/special-date.model';
import { DEFAULT_GRAPH_COLORS } from '../common/constants/default-graph-colors.constant';

type CompsetId = string;
type HotelId = number;

interface UserSettingsPublicInterface {
    /**
     * Contains a list of compset IDs of the current
     * hotel that are used for generating HTML-reports
     *
     * Use updateCompsetsForReports to update this list
     */
    compsetsForReports: Record<HotelId, CompsetId[]> | null;

    /**
     * Updates the compsets list for the current hotel
     * that are used for generating HTML-reports
     */
    updateDisplayCurrency(currency: string | null): Promise<void>;
}

export const UserSettingsS = Symbol.for('UserSettingsS');
@injectable()
export default class UserSettingsService implements UserSettingsPublicInterface {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(UserApiServiceS) private userApiService!: UserApiService;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;

    readonly storeState: UserSettings = this.storeFacade.getState('UserSettings');
    readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');

    constructor() {
        this.storeState.saveRequestLoading.start();
        this.storeState.saveRequestLoading.finish();
        this.storeState.chartColors.loading.start();

        if (this.userStoreState.user) {
            this.displayCurrency = this.userStoreState.user.settings.currencies.displayCurrency;
            this.chartColors = this.userStoreState.user.settings.colors;
            this.storeState.chartColors.loading.finish();
        }

        this.storeFacade.watch(() => this.userStoreState.user, () => {
            this.displayCurrency = this.userStoreState.user
                ? this.userStoreState.user.settings.currencies.displayCurrency
                : null;
            this.chartColors = this.userStoreState.user
                ? this.userStoreState.user.settings.colors
                : null;
            this.storeState.hotelAlertsAndNotifications.loading.reset();
            this.storeState.chartColors.loading.finish();
            this.storeState.compsetSettingsLoading.reset();
        });
    }

    get compsetSettings() {
        this.helperService.dynamicLoading(
            this.storeState.compsetSettingsLoading,
            this.loadCompsetSettings.bind(this),
        );
        return this.storeState.compsetSettings;
    }

    get subscriptions() {
        const { user } = this.userStoreState;
        this.storeState.subscriptions = user
            ? user.settings.subscriptions
            : this.storeState.subscriptions;

        return this.storeState.subscriptions;
    }

    get compsetsForReports() {
        const { settings } = this.userStoreState.user || {};
        if (!settings) return null;

        const { emailReportCompsetIds } = settings;

        return emailReportCompsetIds || null;
    }

    get isSaving() {
        return this.storeState.saveRequestLoading.isLoading();
    }

    get isCompsetSettingsLoading() {
        return this.storeState.compsetSettingsLoading.isLoading();
    }

    get allCurrencies(): CurrencyListModel | null {
        this.helperService.dynamicLoading(this.storeState.currencies.loading, this.loadCurrencies.bind(this));
        return this.storeState.currencies.list;
    }

    get displayCurrency() {
        return this.storeState.currencies.displayCurrency;
    }

    set displayCurrency(value: string | null) {
        this.storeState.currencies.displayCurrency = value;
    }

    get chartColors() {
        return this.storeState.chartColors.list;
    }

    set chartColors(value: string[] | null) {
        this.storeState.chartColors.list = value;
    }

    get intradaySettings() {
        const { user } = this.userStoreState;
        this.storeState.intraday = user
            ? user.settings.intraday
            : this.storeState.intraday;

        return this.storeState.intraday;
    }

    get currencies() {
        return this.storeState.currencies;
    }

    get alertsAndNotifications() {
        const { user } = this.userStoreState;
        this.storeState.alertsAndNotifications = user
            ? user.settings.alertsAndNotifications
            : this.storeState.alertsAndNotifications;

        return this.storeState.alertsAndNotifications;
    }

    get defaultFilters() {
        const { user } = this.userStoreState;
        // After login user have to be in store
        return user!.settings.defaultFilters;
    }

    get alertsAndNotificationsByGroup() {
        const { user } = this.userStoreState;
        this.storeState.alertsAndNotificationsByGroup = user
            ? user.settings.alertsAndNotificationsByGroup
            : this.storeState.alertsAndNotificationsByGroup;

        return this.storeState.alertsAndNotificationsByGroup;
    }

    get hotelAlertsAndNotifications() {
        this.helperService.dynamicLoading(this.storeState.hotelAlertsAndNotifications.loading, this.loadHotelSettings.bind(this));
        return this.storeState.hotelAlertsAndNotifications.hotels;
    }

    get intradaySpecialDates() {
        if (!this.userStoreState.user) return [];

        return this.userStoreState.user.settings.intradaySpecialDates;
    }

    async updateDisplayCurrency(currency: string | null) {
        this.storeState.currencies.loading.start();
        await this.userApiService.updateDisplayCurrency(currency)
            .then(res => {
                if (res && res.settings) {
                    this.displayCurrency = res.settings.currency || null;
                    this.userStoreState.user!.settings.currencies.displayCurrency = res.settings.currency || null;
                }
            })
            .finally(() => this.storeState.currencies.loading.finish());
    }

    async updateChartColors(colors: string[]) {
        this.storeState.chartColors.loading.start();
        try {
            const res = await this.userApiService.updateChartColors(colors);
            if (res && res.settings) {
                this.chartColors = res.settings.colors || null;
                this.userStoreState.user!.settings.colors = res.settings.colors || null;
            }
        } finally {
            this.storeState.chartColors.loading.finish();
        }
    }

    async resetChartColors() {
        return this.updateChartColors(DEFAULT_GRAPH_COLORS);
    }

    async loadCurrencies() {
        this.storeState.currencies.list = await this.userApiService.getAllCurrencies();
        return true;
    }

    async loadCompsetSettings() {
        const { currentHotelId } = this.userStoreState.user || {};
        if (!currentHotelId) {
            return false;
        }

        const compsetState = await this.userApiService.getCurrentCompsetSettings(currentHotelId);
        const newSettings = compsetState.settings || this.compsetSettings;

        this.storeState.compsetSettings = {
            ...this.compsetSettings,
            ...newSettings,
        };
        this.storeState.compsetAllowedReportProviders = compsetState.providers;

        return true;
    }

    async loadHotelSettings() {
        const { user } = this.userStoreState;

        if (!user) {
            return false;
        }

        await this.hotelsService.awaitLoading();
        const { allHotels } = this.hotelsService;

        if (!allHotels.length) {
            return false;
        }

        const { currentHotelId } = user;
        if (currentHotelId === null) {
            return false;
        }

        const res = await this.userApiService.getHotelSettings(currentHotelId);

        if (res.hotels === null) {
            return false;
        }

        $enum(HotelNotifications).forEach(value => {
            if (res.hotels![currentHotelId][value]) return;

            res.hotels![currentHotelId][value] = {};
        });

        this.storeState.hotelAlertsAndNotifications = {
            ...this.storeState.hotelAlertsAndNotifications,
            hotels: res.hotels,
        };

        return true;
    }

    async saveReportsSettings(hotelId?: number) {
        const { saveRequestLoading } = this.storeState;
        saveRequestLoading.start();

        try {
            if (hotelId) {
                await this.userApiService
                    .updateCompsetSettings(this.compsetSettings, hotelId);
            }

            await this.userApiService
                .updateSubscriptions(this.subscriptions);

            this.applyUpdatedSettings('subscriptions');
        } finally {
            saveRequestLoading.finish();
        }
    }

    async saveIntradaySettings() {
        const { saveRequestLoading } = this.storeState;
        saveRequestLoading.start();

        try {
            const { intradaySettings: intraday } = this;
            const { myCompetitorsPercentRateChange } = intraday;

            intraday.myCompetitorsPercentRateChange = +myCompetitorsPercentRateChange;

            await this.userApiService
                .updateSettings({ intraday });
            this.applyUpdatedSettings('intraday');
        } finally {
            saveRequestLoading.finish();
        }
    }

    async saveAlertsAndNotifications(hotelNotifications: HotelNotifications[] = []) {
        const { saveRequestLoading } = this.storeState;

        if (!this.userStoreState.user || !this.userStoreState.user.currentHotelId) {
            return;
        }

        saveRequestLoading.start();

        const { currentHotelId } = this.userStoreState.user;

        const alertsAndNotifications = hotelNotifications
            .reduce((acc, key) => {
                if (!this.hotelAlertsAndNotifications) {
                    return acc;
                }

                const { active, fornovaIds, conditionsValue } = this.hotelAlertsAndNotifications[currentHotelId][key];

                return {
                    ...acc,
                    [key]: {
                        active,
                        fornovaIds,
                        conditionsValue,
                    },
                };
            }, {});

        const payload = JSON.parse(JSON.stringify(this.alertsAndNotificationsByGroup)) as UserAPI.AlertsNotifcationsGroups;

        Object
            .keys(payload)
            .forEach(group => {
                delete payload[group].title;

                Object
                    .keys(payload[group].alertsAndNotifications)
                    .forEach(param => {
                        const params = payload[group]!.alertsAndNotifications![param];
                        delete params.title;
                        delete params.type;
                        params.conditionsValue = params.conditionsValue || [];
                    });
            });

        try {
            if (hotelNotifications.length) {
                await this.userApiService
                    .updateHotelSettings({
                        [currentHotelId]: alertsAndNotifications,
                    } as UserAPI.IUserHotelAlerts);
            }

            await this.userApiService
                .updateSettings({
                    alertsAndNotificationsByGroup: payload,
                });

            this.applyUpdatedSettings('alertsAndNotificationsByGroup');
        } finally {
            saveRequestLoading.finish();
        }
    }

    private applyUpdatedSettings<T extends keyof UserSettings>(settingsKey: T) {
        type AbstractSettings = { settings: { [k: string]: any; } };
        const { settings } = this.userStoreState.user! as AbstractSettings;
        const keysToUpdate = Object.keys(settings[settingsKey]) as (keyof UserSettings[T])[];

        keysToUpdate.forEach(key => {
            settings[settingsKey][key] = this.storeState[settingsKey]![key];
        });
    }

    updateMainPos(compsetId: string, mainPos: string) {
        this.storeState.pos = mainPos;
        return this.userApiService.updateMainPos(compsetId, mainPos);
    }

    async updateDefaultFilters(filters: DefaultFilters) {
        const res = await this.userApiService.updateDefaultFilters(filters);

        (Object.keys(filters) as (keyof DefaultSettingsModel)[]).forEach(key => {
            if (!filters[key]) { return; }

            // NOTE: ts wrong define type: DefaultSettingsModel[keyof DefaultSettingsModel]
            //       should be string | number, ts show never
            this.userStoreState.user!.settings.defaultFilters[key] = filters[key] as never;
        });

        return res;
    }

    isCurrenciesLoading() {
        return this.storeState.currencies.loading.isLoading();
    }

    isChartColorsLoading() {
        return this.storeState.chartColors.loading.isLoading();
    }

    assignHotelSettingsFor(hotelId: number, newSettings: UserAPI.IUserHotelAlerts[0]) {
        const { hotelAlertsAndNotifications } = this.storeState;

        hotelAlertsAndNotifications.hotels = {
            ...hotelAlertsAndNotifications.hotels,
            [hotelId]: {
                ...hotelAlertsAndNotifications.hotels![hotelId],
                ...newSettings,
            },
        };

        this.storeState.hotelAlertsAndNotifications = {
            ...hotelAlertsAndNotifications,
        };
    }

    async addSpecialDate(specialDate: SpecialDateModel) {
        const { user } = this.userStoreState;
        if (!user) return;

        const updatedList = await this.userApiService.addSpecialDate(specialDate);

        user.settings.intradaySpecialDates = updatedList;
    }

    async deleteSpecialDate(specialDateId: string) {
        const { user } = this.userStoreState;
        if (!user) return;

        await this.userApiService.deleteSpecialDate(specialDateId);

        user.settings.intradaySpecialDates = user.settings.intradaySpecialDates
            .filter(date => date.id !== specialDateId);
    }

    async updateSpecialDate(specialDate: SpecialDateModel) {
        const { user } = this.userStoreState;
        if (!user) return;

        const updatedList = await this.userApiService.updateSpecialDate(specialDate);

        user.settings.intradaySpecialDates = updatedList;
    }

    async updateCompsetsForReports(compsetIds: string[]) {
        const { user } = this.userStoreState;
        if (!user) return;

        if (!user.settings.emailReportCompsetIds) {
            user.settings.emailReportCompsetIds = {};
        }

        user.settings.emailReportCompsetIds[user.currentHotelId!] = compsetIds;

        await this.userApiService.updateSettings({
            emailReportCompsetIds: user.settings.emailReportCompsetIds,
        });
    }
}
