import { Expose, Transform } from 'class-transformer';

type RVSProperty = string;

export default class RVSWeightSettingsModel {
    @Expose({ name: 'fornova_id' })
    hotelId!: number;

    @Expose({ name: 'rvs_weights' })
    @Transform((weights: Record<RVSProperty, number>) => {
        const result: Record<RVSProperty, number> = {};

        Object.entries(weights).forEach(([property, weight]) => {
            result[property] = +(weight * 100).toFixed(2);
        });

        return result;
    })
    weights!: Record<RVSProperty, number>;

    get isWeightsValid(): boolean {
        return Object
            .values(this.weights)
            .reduce((acc, weight) => acc + weight, 0) === 1.0;
    }

    duplicate(): RVSWeightSettingsModel {
        const duplicate = Object.assign(new RVSWeightSettingsModel(), this);
        duplicate.weights = { ...this.weights };

        return duplicate;
    }
}
