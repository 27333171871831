import { plainToClass } from 'class-transformer';
import { injectable } from '@/inversify.injectable';
import { inject } from '@/inversify.inject';

import ApiService, { ApiServiceS } from '../common/services/api.service';
import RVSWeightSettingsModel from './models/rvs-weights-settings.model';

@injectable()
export default class SettingsApiService {
    @inject(ApiServiceS)
    private apiService!: ApiService;

    // NOTE: RVS Weight settings
    async getWeightsSettings(hotelId: number, chainId: string) {
        const { data } = await this.apiService.get(`hotel-mappings/${hotelId}/${chainId}`);

        return plainToClass(RVSWeightSettingsModel, data, { excludeExtraneousValues: true });
    }

    updateWeightsSettings(hotelId: number | number[], chainId: string, newWeights: Record<string, number>) {
        if (Array.isArray(hotelId)) {
            return this.apiService.put('hotel-mappings/batch-update', {
                fornova_ids: hotelId,
                chain_id: chainId,
                rvs_weights: newWeights,
            });
        }

        return this.apiService.put(`hotel-mappings/${hotelId}/${chainId}`, {
            rvs_weights: newWeights,
        });
    }
}
