import { inject, injectable } from '@/inversify';

import RVSDocumentModel from './models/rvs-document.model';
import UserService, { UserServiceS } from '../user/user.service';
import { ASSESSMENT_TYPES } from '../common/constants';
import Day from '../common/types/day.type';
import { calculateDiff } from '../common/utils/calculate-diff';

@injectable()
export default class RateValueScoreService {
    @inject(UserServiceS)
    private userService!: UserService;

    getMainValue(day: Day, doc?: RVSDocumentModel | null) {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) return null;
        if (!doc) return null;

        return Math.round(doc.checkinDates[day]?.[currentHotelId]?.score || 0);
    }

    getCompsetAverageValue(day: Day, doc?: RVSDocumentModel | null) {
        if (!this.userService.currentHotelId) return null;
        if (!doc) return null;

        return Math.round(doc.averages[day]);
    }

    getDifference(day: Day, doc?: RVSDocumentModel | null) {
        if (!this.userService.currentHotelId) return null;
        if (!doc) return null;

        const mainValue = this.getMainValue(day, doc)!;
        const averageValue = this.getCompsetAverageValue(day, doc)!;

        return calculateDiff(mainValue, averageValue);
    }

    getAssessment(day: Day, doc?: RVSDocumentModel | null) {
        if (!this.userService.currentHotelId) return null;
        if (!doc) return null;

        const diff = this.getDifference(day, doc)!;

        if (diff < -5) {
            return ASSESSMENT_TYPES.BAD;
        }

        if (diff > 5) {
            return ASSESSMENT_TYPES.GOOD;
        }

        return ASSESSMENT_TYPES.NORMAL;
    }

    isNoData(day: Day, doc?: RVSDocumentModel | null) {
        if (!doc) return true;

        return !(day in (doc?.checkinDates || {})) || !Object.keys(doc.checkinDates[day]).length;
    }
}
