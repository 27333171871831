import { Expose, plainToClass, Transform } from 'class-transformer';
import { IsNumber, IsOptional, Validate } from 'class-validator';
import moment from 'moment';

import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import RatesCheckinDayModel from '@/modules/rates/models/rates-checkin-day.model';

import type Day from '@/modules/common/types/day.type';
import PriceHistoryTrendDataValidator from './validators/trend-data.validator';

export default class RatesPriceHistoryModel {
    @Expose()
    @Transform((_, plain) => plain.day)
    @IsNumber()
    @IsOptional()
    requestDay: Day | null = null;

    @Expose()
    @Transform((_, plain) => {
        const trendData: any = {};

        if (!plain.trend_data || !Object.keys(plain.trend_data).length) {
            return plain.trend_data;
        }

        // If trend keys are from the future, it is probably because of follow the sun feature.
        // In this case we get day diff between today and last key in trends.
        let followTheSunDaysOffset = 0;
        const trendRawDates = Object.keys(plain.trend_data);
        const trendDatedsSorted = trendRawDates.map(item => moment.utc(item, 'DD-MM-YYYY')).sort((a, b) => b.valueOf() - a.valueOf());

        if (trendDatedsSorted.length) {
            const lastTrendsKeyInUtc = trendDatedsSorted[0];
            const todayInUtc = (moment.utc()).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            });
            followTheSunDaysOffset = lastTrendsKeyInUtc.diff(todayInUtc, 'days');

            if (followTheSunDaysOffset < 0) {
                followTheSunDaysOffset = 0;
            }
        }

        Object.keys(plain.trend_data).forEach(plainDate => {
            // Substract followTheSunDaysOffset from trend keys.
            const date = moment.utc(plainDate, 'DD-MM-YYYY').subtract(followTheSunDaysOffset, 'days').format('DD-MM-YYYY');
            trendData[date] = { hotels: {} };
            const td = trendData[date];
            const plainTd = plain.trend_data[plainDate];

            Object.keys(plainTd).forEach(hotelId => {
                if (hotelId !== 'day_statistics') {
                    td.hotels[hotelId] = { rooms: {}, link: null };
                    const hotel = td.hotels[hotelId];
                    const plainHotel = plain.trend_data[plainDate][hotelId];
                    hotel.pax = plainHotel.pax && plainHotel.pax.length ? plainHotel.pax : null;

                    if (plainHotel.rooms) {
                        Object.keys(plainHotel.rooms).forEach(roomTypeId => {
                            hotel.rooms[roomTypeId] = [];
                            hotel.updateDate = plainHotel.update_date;
                            hotel.losRestriction = plainHotel.los_restricted;
                            hotel.link = plainHotel.link;
                            const ratesDocumentItems: RatesDocumentItemModel[] = [];
                            hotel.rooms[roomTypeId] = ratesDocumentItems;

                            Object
                                .entries(plainHotel.rooms[roomTypeId])
                                .forEach(([__, ratesDocumentItem]) => {
                                    const roomInstance = plainToClass(
                                        RatesDocumentItemModel,
                                        ratesDocumentItem,
                                        { excludeExtraneousValues: true },
                                    );
                                    ratesDocumentItems.push(roomInstance);
                                });
                        });
                    }
                }

                const { day_statistics: dayStatistics } = plainTd;
                td.occupancy = null;
                td.demand = null;

                if (!dayStatistics) return;

                if (Number.isFinite(dayStatistics.demand)) {
                    td.demand = dayStatistics.demand < 0 ? 0 : Math.round(dayStatistics.demand * 100);
                }

                if (Number.isFinite(dayStatistics.occupancy)) {
                    td.occupancy = dayStatistics.occupancy < 0 ? 0 : Math.round(dayStatistics.occupancy);
                }
            });
        });

        return trendData;
    })
    @Validate(PriceHistoryTrendDataValidator)
    trendData!: {
        [date: string]: RatesCheckinDayModel | null;
    };
}
